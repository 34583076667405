body {
	font-family: 'Roboto', sans-serif;
}
/* Generated by Font Squirrel (http://www.fontsquirrel.com) on April 9, 2012 */
@font-face {
    font-family: 'iconsMoon';
    src: url('/media/css/font/icomoon/iconsMoon/icomoon_font-webfont.eot');
    src: url('/media/css/font/icomoon/iconsMoon/icomoon_font-webfont.svg#IcoMoonRegular') format('svg'),
         url('/media/css/font/icomoon/iconsMoon/icomoon_font-webfont.woff') format('woff'),
         url('/media/css/font/icomoon/iconsMoon/icomoon_font-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}/* Artfully masterminded by ZURB  */

/* --------------------------------------------------
   Table of Contents
-----------------------------------------------------
:: Shared Styles
:: Page Name 1
:: Page Name 2
*/

/* Alterações para maiores que 767px ---------------------- */
@media only screen and (max-width: 900px) {

}

/* Alterações para resoluções entre 450px e 767px ---------------------- */
@media only screen and (max-width: 767px) and (min-width: 0px) {

}

/* Alterações para resoluções até 450px ---------------------- */
@media only screen and (max-width: 450px) {

}

/*Button G+ adjustes for Foundation*/
table.gc-bubbleDefault {background: transparent;}
.pls-tailtop, .pls-tailright, .pls-tailbottom, .pls-tailleft {height: 8px;}

.iconsMoon {
	font-family: iconsMoon;
	font-weight: normal;
	letter-spacing: 0!important; /*problemas com Opera*/
}

.size12 {font-size: 0.7em;}
.size14 {font-size: 0.850em;}
.size16 {font-size: 1.000em;}
.size20 {font-size: 1.250em;}
.size24 {font-size: 1.500em;}
.size28 {font-size: 1.750em;}


/* -----------------------------------------
   HOME
----------------------------------------- */
a:hover {text-decoration: underline;}

.logo {
	font-size: 2.5em;
	padding: 0.5em 0;
	text-align: center;
}

	.logo a {color: #00A0B0;}
	.logo a:hover {text-decoration: none;}
	.logo a:hover, .menu a:hover {color: #EB6841;}

.social_top {
	padding: 2em 0 0 0;
}

.menu {
	border-top: 1px solid #DDD;
	border-bottom: 1px solid #DDD;
	padding: 0.8em 0;
	font-size: 0.885em;
	/*margin-bottom: 1em;*/
}

	.menu a {
		margin-right: 0.785em;
		color: #00A0B0;
	}

.bread_crumb {
	background: #F4F2F2;
	padding: 0 5px 5px;
	color: #555555;
	font-size: 0.885em;
	text-transform: uppercase;
}
	.bread_crumb a, .bread_crumb span {font-size: 0.685em}
	.bread_crumb a {color: #555;}
	.bread_crumb span {color: #999;}

	.bread_crumb a:hover {text-decoration: underline;}

.box_padrao {
	border: 1px solid #DDD;
	border-radius: 3px;
	padding-bottom: 10px;
}

	.box_meses h2, .box_normal h3 {
		background: none repeat scroll 0 0 #F4F2F2;
	    font-size: 1em;
	    margin-top: 0;
	    padding: 10px;
	    border-bottom: 1px solid #DDD;
	}

	a.box_style, .text_box {
		font-size: 0.850em;
		display: block;
		padding: 5px 20px;
		float: left;
	}

.tb_mes {}

	.tb_mes thead tr th, .tb_mes tfoot tr td, .tb_mes tbody tr td {padding: 0.625em}

	.tb_mes tr td, .tb_mes th {
		text-align: center;
	}

	.fer, .leg_fer {background: #90BA00; color: #FFF;}
	.dia, .leg_dia {background: orange; color: #FFF;}

	.leg {
		min-width: 14px;
		min-height: 14px;
		float: left;
		margin-right: 10px;
	}

	.label_dia {
		padding: 5px 0;
		text-align: center;
		width: 36px;
		height: 30px;
		color: #FFF;
		border-radius: 3px;
		float: left;
		margin: 0 10px;
	}


	.box_normal > .dis_tb:last-child {border: none; margin: 0;}
	.dis_tb {
		display: table;
		width: 100%;
		height: 30px;
		line-height: 20px;
		margin-bottom: 10px;
		padding-bottom: 10px;
		border-bottom: 1px solid #DDD;
	}



/* -----------------------------------------
   Page Name 2
----------------------------------------- */


